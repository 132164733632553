import React from "react";
import BigText from "./BigText";
import Footer from "./Footer";
import TopText from "./TopText";
import { useRef, useEffect, useState } from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
import Video from "../assets/layout/green_2.mp4";

const Slide4 = ({ childFunc }) => {
  const myvideo = useRef(null);
  const [mystream, setmystream] = useState(null);
  const [videoswitch, setvideo] = useState(true);

  const vidRefa = useRef(null);

  const handlePlayVideo4 = () => {
    vidRefa.current.play();
  };
  const stopPlayVideo4 = () => {
    vidRefa.current.pause();
  };

  useEffect(() => {
    childFunc.handlePlayVideo4 = handlePlayVideo4;
    childFunc.stopPlayVideo4 = stopPlayVideo4;
  }, []);

  return (
    <div className="slide" id="slide4" data-id="4">
      <div className="video-wrap">
        <video playsInline id="video4" ref={vidRefa}>
          <source src={Video} type="video/mp4" />
        </video>
      </div>

      <Footer skipButton skipText="Skip" goto="0" />
    </div>
  );
};
export default Slide4;
