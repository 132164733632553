import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";
function VideoDialogButtons(props) {
  return (
    <div className="video-dialog-buttons">
      <div className="video-dialog">
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum at
        lacus lobortis mauris pretium consectetur. Nullam vel risus bibendum,
        varius mauris at, rhoncus purus.
      </div>

      <button className="footer-small-button go-func" data-func="0">
        <FontAwesomeIcon icon={regular("times")} /> No
      </button>
      <button className="footer-small-button  go-func" data-func="2">
        <FontAwesomeIcon icon={regular("check")} /> Yes
      </button>
    </div>
  );
}
export default VideoDialogButtons;
