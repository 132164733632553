import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { solid, regular, brands, light } from '@fortawesome/fontawesome-svg-core/import.macro'; // <-- import styles to be used
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "bootstrap/dist/js/bootstrap.bundle.min";
import { BrowserRouter, Routes, Route  } from "react-router-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
   
      <App />
 );

 


// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
