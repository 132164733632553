import React from "react";
import BigText from "./BigText";
import Footer from "./Footer";
import TopText from "./TopText";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  solid,
  regular,
  brands,
  light,
} from "@fortawesome/fontawesome-svg-core/import.macro";

function Slide0() {
  return (
    <div className="slide active pre-active" id="slide0" data-id="0">
      <div className="row justify-content-center align-items-center h-100">
        <div className="col-md-6 cytat">
          "Imagine a supercomputer that is able to generate enormous resources
          of the computing power you need, has access <br />
          to tools such as AI, and allows you to use them anywhere in the
          world."
          <span>— Magda Dziewguć, Google Cloud Poland Country Director</span>
        </div>
        <Footer
          extraButton
          extraButtonClass="go-func big-button-center"
          extraButtonFunc="1"
          extraButtonText="Tap to start"
        />
      </div>
    </div>
  );
}
export default Slide0;
