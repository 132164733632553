import React from "react";
import Footer from "./Footer";
import ReactGA from "react-ga";

import { useRef, useEffect, useState } from "react";
import $ from "jquery";

import Video from "../assets/layout/green_1.mp4";

const Slide1 = ({ childFunc, changeSlide }) => {
  // const myvideo = useRef(null);
  // const [mystream, setmystream] = useState(null);
  // const [videoswitch, setvideo] = useState(true);

  const vidRef = useRef(null);

  const handlePlayVideo = () => {
    vidRef.current.currentTime = 0;
    $(".video-dialog-buttons").removeClass("active");
    $("#slide1 .skipAnim").removeClass("d-none");
    vidRef.current.play();
  };
  const handleEndVideo = () => {
    vidRef.current.pause();
    vidRef.current.currentTime = vidRef.current.duration - 0.1;
    $(".timer").html("00:00");
    changeSlide(1, 0);
    ReactGA.event({ category: "Cloud_Video", action: "Cloud_Finished" });
  };
  const stopPlayVideo = () => {
    vidRef.current.pause();
  };

  useEffect(() => {
    childFunc.handlePlayVideo = handlePlayVideo;
    childFunc.handleEndVideo = handleEndVideo;
    childFunc.stopPlayVideo = stopPlayVideo;
  }, []);

  return (
    <div className="slide" id="slide1" data-id="1">
      <div className="video-wrap">
        <div className="timer"></div>
        <video playsInline id="video1" ref={vidRef}>
          <source src={Video} type="video/mp4" />
        </video>
      </div>
      <Footer skipButton skipText="Skip" goto="0" />
    </div>
  );
};
export default Slide1;
